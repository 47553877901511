import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Blurbs from "./blurbs"

const Nocode = () => {
    const data = useStaticQuery(graphql`
        query Nocode {
            image_cheap: file(relativePath: {eq: "digital-box--cheap.png"}) {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    height: 150
                    placeholder: BLURRED
                  )
                }
            }
            image_fast: file(relativePath: {eq: "digital-box--fast.png"}) {
                childImageSharp {
                    gatsbyImageData(
                    quality: 100
                    height: 150
                    placeholder: BLURRED
                    )
                }
            }
            image_chill: file(relativePath: {eq: "digital-box--chill.png"}) {
                childImageSharp {
                    gatsbyImageData(
                    quality: 100
                    height: 150
                    placeholder: BLURRED
                    )
                }
            }
        }
    `)

    const benefitsNoCode = [
        {
          image: data.image_fast.childImageSharp.gatsbyImageData,
          title: "3x plus rapide", 
          desc: "Développez votre projet en 3-9 semaines, contre 3 à 9 mois avec un développement traditionnel en full code.",
        },
        {
          image: data.image_cheap.childImageSharp.gatsbyImageData,
          title: "2x moins cher", 
          desc: "Divisez le prix de votre projet digital par 2, pas de retards ou de mauvaises surprises techniques / bugs et dépassements.",
        },
        {
          image: data.image_chill.childImageSharp.gatsbyImageData,
          title: "0 maintenance", 
          desc: "Pas besoin de maintenance, de mises à jour de plugins, pas de stress de changements de versions ou de bugs et failles de sécurité.",
        },
    ]

    return (
        <Blurbs items={benefitsNoCode} />
    )
}

export default Nocode