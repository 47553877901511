import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Nocode from "../components/nocode"

import { css } from "@emotion/core"
import { colors } from "../styles/constants"

const SolutionTemplate = (props) => {
    const solution = props.data.solution.data
    const slug = solution.Slug
    const title = solution.Name
    const desc = solution.Description

    return (
        <Layout>
            <Seo title={`${title} en Suisse romande - ${title} No-Code`} description={desc} />

            <div 
                css={productHeader}
                className="bg--beige"
            >
                <div className="container">
                    <h1>Construire une solution de {title} en NoCode en Suisse romande</h1>
                    <p>{desc}</p>
                </div>
            </div>

            <div style={{
                'padding': '60px 0',
            }} >
                <div className="container">
                    <h2>{title} No-Code : notre approche</h2>
                    <h3>1 - <span className="stabilo">Cadrage</span> du projet de votre {title} NoCode</h3>
                    <p>Nous travaillons avec vous et vous conseillons pour finaliser le cahier des charges qui orientera notre travail sur votre {title} No-Code, listant les spécifications en termes de fonctionnalités et expérience utilisateur.ice.</p>
                    <h3>2- <span className="stabilo alt1">Choix des outils</span> pour votre {title} no-code</h3>
                    <p>Nous vous orientons dans le choix des outils No-Code les plus adaptés à vos besoins pour développer votre {title} no code. Nous vous conseillons pour s'assurer que les outils permettent d'accomplir votre <i>use case</i>, et vous présentons d'autres possibilités offertes par les outils retenus.</p>
                    <h3>3 - <span className="stabilo alt2">Conception</span> de votre {title} NoCode</h3>
                    <p>Nous mettons en place les outils NoCode choisis pour votre {title}. Nous faisons le <i>setup</i> de votre {title} nocode afin que la solution soit fonctionnelle de manière "clé-en-main".</p>
                    <h3>4 - <span className="stabilo alt3">Livraison</span> et formation des équipes à votre {title}</h3>
                    <p>Une fois votre {title} livrée, nous préparons le contenu pédagogique nécessaire à l'indépendance de vos équipes selon les souhaits initiaux : gestion, voire même capacités de faire évoluer vous-mêmes la solution grâce aux outils NoCode mis en place.</p>
                    <h3>5 - <span className="stabilo alt4">Améliorations</span> de votre {title} low-code en "mode agile"</h3>
                    <p>Vous souhaitez améliorer votre solution de {title} au fur et à mesure que vous vous développer ? C'est tout à fait possible et même conseillé, plutôt que de vous lancer dans un développement monolithique. L'amélioration continue et graduelle de votre solution de {title} est simple et rapide grâce au No-Code, et nous vous accompagnons avec plaisir.</p>
                </div>
            </div>

            <div 
                style={{
                    'padding': '66px 0',
                }} 
                className="bg--color1"
            >
                <div className="container">
                    <h2>Création de {title} : pourquoi le choix du no code ?</h2>
                    <p>Les <b>plates-formes no-code et low-code</b> ont révolutionné la façon dont nous créons des sites Web et des applications. Ils deviennent de plus en plus populaires car ils permettent aux utilisateurs sans connaissances techniques de créer des pages Web, des applications (${title}) et des produits numériques simplement et rapidement, pour des coûts réduits. Les plates-formes #NoCode telles que Webflow ou Glide offrent une interface utilisateur intuitive et facile à utiliser, ce qui simplifie le développement de produits numériques. Elles <b>enlèvent aussi les coûts (et stress) de maintenance</b> et de gestion de plugins ou failles de sécurité par faute de mises à jour.</p>
                    <p>En plus de permettre aux utilisateur.ice.s non techniques de créer ou gérer des applications, les environnements no-code/low-code peuvent également être extrêmement utiles pour les entreprises qui ont besoin de lancer rapidement un site Web ou une application. Avec un <b>investissement de temps et budget minimal</b>, les entreprises, institutions, et startups peuvent tirer parti de ces plateformes pour <b>créer des projets digitaux plus rapidement que jamais, en quelques jours/semaines plutôt que mois</b>. Cela permet aux équipes de collaborer à la création de sites Web même si les membres ont différents niveaux d'expertise en matière de codage - la plateforme fait le plus gros du travail "technique" pour vous !</p>
                    
                    <Nocode />
                </div>
            </div>

            <div className="container">
                <div className="form-wrapper" id="contact">
                    <h2><span className="stabilo">Demander une offre</span><br/>{title} NoCode en Suisse romande</h2>
                    <form name={`demande-solution-${slug}`} data-netlify="true" method="post" netlify-honeypot="bot-field" action="/merci">
                        <div className="form-container">
                            <label htmlFor="first-name" className="field half-width half-width-left">
                                <span>Prénom</span>
                                <input id="first-name" type="text" name="first-name" aria-label="first-name" placeholder="Emilie" required />
                            </label>

                            <label htmlFor="last-name" className="field half-width half-width-right">
                                <span>Nom</span>
                                <input id="last-name" type="text" name="last-name" aria-label="last-name" placeholder="Duvalais" required />
                            </label>

                            <label htmlFor="email" className="field half-width half-width-left">
                                <span>Email</span>
                                <input id="email" type="email" name="email" aria-label="email" placeholder="mon.email@duvalais.ch" required />
                            </label>

                            <label htmlFor="company" className="field half-width half-width-right">
                                <span>Entreprise</span>
                                <input id="company" type="text" name="company" aria-label="company" placeholder="MonEntreprise Sàrl" required />
                            </label>

                            <label className="field half-width half-width-left" htmlFor="localisation">
                                <span>Localisation de votre entrise</span>
                                <input id="localisation" type="text" name="localisation" aria-label="localisation" placeholder="Place Riponne, 1004 Lausanne, VD" required/>
                            </label>

                            <label htmlFor="phone" className="field half-width half-width-right">
                                <span>Téléphone</span>
                                <input id="phone" type="phone" name="phone" aria-label="phone" placeholder="Duvalais" required />
                            </label>
                
                            <label className="field full-width" htmlFor="website">
                                <span>Lien de votre site web</span>
                                <input id="website" type="url" name="website" aria-label="website" placeholder="https://mon-entreprise.ch" />
                            </label>
                
                            <label className="field half-width half-width-left" htmlFor="max-budget">
                            <span>Quel est votre enveloppe budgétaire (indicatif) ?</span>
                            <input id="max-budget" type="number" name="max-budget" aria-label="max-budget" placeholder="X.- CHF" min="100" max="100000" required/>
                            </label>
                
                            <label className="field full-width" htmlFor="message">
                                <span>Si nécessaire, vous pouvez nous donner plus de détails ici :</span>
                                <textarea id="message" type="text" name="message" aria-label="message" placeholder="Je vous explique : j'aimerais réaliser ..." rows="4"></textarea>
                            </label>

                            <input type="hidden" name="bot-field" aria-label="bot field" />
                            <input type="hidden" name="form-name"  aria-label="form name" value={`demande-solution-${slug}`} />

                            <div>
                                <button className="send-button" type="submit" aria-label="Envoyer">Envoyer la demande</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    )
} 

export default SolutionTemplate


const productHeader = css`
    padding-top: 80px;
    padding-bottom: 40px;
    // background-color: ${colors.accent1_pale};
`

export const solutionTemplateQuery = graphql`
    query solutionTemplateQuery ($slug: String!) {
        solution: airtable(data: {Slug: {eq: $slug}}, table: {eq: "Solutions"}) {
            data {
              Name
              Description
              Slug
            }
          }
    }
`